import { Component, OnInit } from '@angular/core';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu } from '@ng-bootstrap/ng-bootstrap';
import { ThrowStmt } from '@angular/compiler';
import { FormControl, Validators } from '@angular/forms';
const BASE_SCHOOL = 'Sri Chaitanya School';
const OUR_BLUE = '#1976d2';
const OUR_RED = 'red';
const CHEST_MEASURE_LEGEND = 'Chest Measure of Garment';
const SHOULDER_MEASURE_LEGEND = 'Shoulder Measure of Garment';
const WAIST_MEASURE_LEGEND = 'Waist Measure of Garment';
const SRI_CHAITANYA_SCHOOL = BASE_SCHOOL;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'ReInvent Uniform Measurements App';
  JSON = {};
  sideLengthMeasurementTaken = false;
  alertMessage = '';
  showAlert = false;
  topMeasurementInRange: boolean;
  topMeasurementLegendColor = OUR_BLUE;
  bottomMeasurementInRange: boolean;
  bottomMeasurementLegendColor = OUR_BLUE;
  editing = true;
  viewingResults = false;
  bottomLength = new FormControl();
  genderSelector = new FormControl();
  groupSelector = new FormControl();
  chestm = new FormControl();
  waistm = new FormControl();

  constructor(

  ) {
    // cdRef.detectChanges();
    this.JSON = JSON;
    window.scrollTo(0, 0);
  }

  schoolSelected = false;
  genderSelected = false;
  groupSelected = false;
  showMeasurementPanel = false;
  genderSelectedValue = '';
  groupSelectedValue = '';
  groupSelectedGradeValue = '';
  genderOption = '';
  groupOption = '';
  waistMeasured = false;
  chestMeasured = false;
  sideLengthDisplay = 0.0;
  showSizes = false;
  savedMeasurements = false;

  schools = [SRI_CHAITANYA_SCHOOL];
  selectedSchool = BASE_SCHOOL;
  genderOptions = [{ key: 'boys', displayText: 'Boy' }, { key: 'girls', displayText: 'Girl' }];
  // boysGroupOptions = [{key: 'nur2iii', grades: 'Nursery to III'}, {key: 'iv2v', grades: 'IV to V'}, {key: 'vi2x', grades: 'VI to X'}]
  // girlGroupOptions = [{key: 'nur2ii', grades: 'Nursery to II'}, {key: 'iii',  grades: 'III'}, {key: 'iv2x', grades: 'IV to X'}]
  boysGroupsGradeMap = [{ key: 'nur2ukg', grade: 'Nursery' },
  { key: 'nur2ukg', grade: 'LKG' },
  { key: 'nur2ukg', grade: 'UKG' },
  { key: 'i2iii', grade: 'I' },
  { key: 'i2iii', grade: 'II' },
  { key: 'i2iii', grade: 'III' },
  { key: 'iv2v', grade: 'IV' },
  { key: 'iv2v', grade: 'V' },
  { key: 'vi2x', grade: 'VI' },
  { key: 'vi2x', grade: 'VII' },
  { key: 'vi2x', grade: 'VIII' },
  { key: 'vi2x', grade: 'IX' },
  { key: 'vi2x', grade: 'X' }
  ];
  boysGroupOptions =
    [{ key: 'nur2ukg', grades: 'Nursery to UKG', gradeNames: ['Nur', 'LKG', 'UKG'] },
    { key: 'i2iii', grades: 'I to III', gradeNames: ['I', 'II', 'III'] },
    { key: 'iv2v', grades: 'IV to V', gradeNames: ['IV', 'V'] },
    { key: 'vi2x', grades: 'VI to X', gradeNames: ['VI', 'VII', 'VIII', 'IX', 'X'] },
    ];
  girlsGroupsGradeMap = [{ key: 'nur2ii', grade: 'Nursery' },
  { key: 'nur2ii', grade: 'LKG' },
  { key: 'nur2ii', grade: 'UKG' },
  { key: 'nur2ii', grade: 'I' },
  { key: 'nur2ii', grade: 'II' },
  { key: 'iii', grade: 'III' },
  { key: 'iv2x', grade: 'IV' },
  { key: 'iv2x', grade: 'V' },
  { key: 'iv2x', grade: 'VI' },
  { key: 'iv2x', grade: 'VII' },
  { key: 'iv2x', grade: 'VIII' },
  { key: 'iv2x', grade: 'IX' },
  { key: 'iv2x', grade: 'X' }
  ];
  girlGroupOptions = [
    { key: 'nur2ii', grades: 'Nursery to II', gradeNames: ['Nur', 'LKG', 'UKG', 'I', 'II'] },
    { key: 'iii', grades: 'III', gradeNames: ['III'] },
    { key: 'iv2x', grades: 'IV to X', gradeNames: ['IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X'] }
  ];
  groupOptions: any[] = ['Select Gender first'];
  groupsGradeMap: any[] = [];

  cha = {
    boys: {
      nur2ukg: {
        topMeasurements: {
          chestMinStops: [31.8, 33.7, 35.6, 37.5, 39.4, 41.3, 43.2, 45.1, 48.1],
          chestMaxStops: [33.6, 35.5, 37.4, 39.3, 41.2, 43.1, 45.0, 48.0, 51.2],
          chestMeasurementTuples: [],
          topLengths: [44.0, 46.5, 49.0, 51.5, 54.0, 57.5, 60.5, 63.5, 63.5],
          mainTop: { name: 'Shirt - Red Chk', sizes: ['P3', 'P4', 'P5', 'P6', 'P8', 'P10', 'P12', 'P14', 'P16'] },
          otherProducts: [
            { name: 'Apron', sizes: ['S', 'S', 'M', 'M', 'M', 'L', 'L', 'L', 'L'] },
            { name: 'T-Shirt', sizes: ['P5', 'P6', 'P8', 'P8', 'P10', 'P12', 'P12', 'P14', 'P16'] },
            { name: 'Cardigan', sizes: ['4', '4', '6', '6', '8', '10', '10', '12', '14'] },
          ]
        },
        bottomMeasurements: {
          waistMinStops: [20.4, 22.9, 25.4, 28.1, 30.5, 33.1, 35.8, 38.5, 41.2, 43.9, 46.6],
          waistRelaxStops: [22.8, 25.3, 28.0, 30.4, 33.0, 35.7, 38.4, 41.1, 43.8, 46.5, 49.2],
          waistMeasurementTuples: [],
          sideLengths: [[29.0], [31.0], [33.0], [35.0], [37.0], [38.0], [39.5], [39.5], [39.5], [39.5], [39.5]],
          mainBottom: {
            name: 'Shorts',
            sizes: [['16'], ['18'], ['20'], ['22'], ['24'], ['26'], ['28'], ['30'], ['32'], ['34'], ['36']]
          },
          otherProducts: [
            { name: 'Belt', sizes: ['S', 'S', 'S', 'S', 'M', 'M', 'M', 'M', 'M', 'XL', 'XL'] },
          ]
        },
        accessories: [
          { grade: 'Nursery', name: 'Socks', sizes: ['2'] },
          { grade: 'LKG', name: 'Socks', sizes: ['3'] },
          { grade: 'UKG', name: 'Socks', sizes: ['3'] }
        ],
        images: {
          topMeasurementsImg: '../assets/images/dashboard/measurements/reinvent-uniform-top-measure.png',
          bottomMeasurementsImg: '../assets/images/dashboard/measurements/reinvent-uniform-bottom-measure.png'
        },
        legend: {
          topMeasurementsLegend: CHEST_MEASURE_LEGEND,
          bottomMeasurementsLegend: WAIST_MEASURE_LEGEND
        }
      },
      i2iii: {
        topMeasurements: {
          chestMinStops: [33.7, 35.6, 37.5, 39.4, 41.3, 43.2, 45.1, 48.1, 51.3],
          chestMaxStops: [35.5, 37.4, 39.3, 41.2, 43.1, 45.0, 48.0, 51.2, 54.4],
          chestMeasurementTuples: [],
          topLengths: [46.5, 49.0, 51.5, 54.0, 57.5, 60.5, 63.5, 63.5, 63.5],
          mainTop: { name: 'Shirt - Red Chk', sizes: ['P4', 'P5', 'P6', 'P8', 'P10', 'P12', 'P14', 'P16', 'P18'] },
          otherProducts: [
            { name: 'Apron', sizes: ['S', 'M', 'M', 'M', 'L', 'L', 'L', 'L', 'XL'] },
            { name: 'T-Shirt', sizes: ['P6', 'P8', 'P8', 'P10', 'P12', 'P12', 'P14', 'P16', 'P16'] },
            { name: 'Cardigan', sizes: ['4', '6', '6', '8', '10', '10', '12', '14', '16'] },
          ]
        },
        bottomMeasurements: {
          waistMinStops: [22.9, 25.4, 28.1, 30.5, 33.1, 35.8, 38.5, 41.2, 43.9, 46.6],
          waistRelaxStops: [25.3, 28.0, 30.4, 33.0, 35.7, 38.4, 41.1, 43.8, 46.5, 49.2],
          waistMeasurementTuples: [],
          sideLengths: [[31.0], [33.0], [35.0], [37.0], [39.0], [40.5], [42.0], [42.0], [42.0], [42.0]],
          mainBottom: {
            name: 'Shorts',
            sizes: [['18'], ['20'], ['22'], ['24'], ['26'], ['28'], ['30'], ['32'], ['34'], ['36']]
          },
          otherProducts: [
            { name: 'Belt', sizes: ['S', 'S', 'S', 'M', 'M', 'M', 'M', 'M', 'XL', 'XL'] },
          ]
        },
        accessories: [
          { grade: 'I', name: 'Socks', sizes: ['3'] },
          { grade: 'II', name: 'Socks', sizes: ['4'] },
          { grade: 'III', name: 'Socks', sizes: ['4'] }
        ],
        images: {
          topMeasurementsImg: '../assets/images/dashboard/measurements/reinvent-uniform-top-measure.png',
          bottomMeasurementsImg: '../assets/images/dashboard/measurements/reinvent-uniform-bottom-measure.png'
        },
        legend: {
          topMeasurementsLegend: CHEST_MEASURE_LEGEND,
          bottomMeasurementsLegend: WAIST_MEASURE_LEGEND
        }
      },
      iv2v: {
        topMeasurements: {
          chestMinStops: [37.5, 39.4, 41.3, 43.2, 45.1, 48.1, 51.3, 54.5, 57.7, 60.9, 64.1],
          chestMaxStops: [39.3, 41.2, 43.1, 45.0, 48.0, 51.2, 54.4, 57.6, 60.8, 64.0, 67.2],
          chestMeasurementTuples: [],
          topLengths: [53.0, 56.0, 59.5, 63.0, 67.0, 71.0, 73.5, 73.5, 73.5, 73.5, 73.5],
          mainTop: { name: 'Tie Shirt', sizes: ['6', '8', '10', '12', '14', '16', '18', '20', '22', '24', '26'] },
          otherProducts: [
            { name: 'Waist Coat', sizes: ['XS', 'XS', 'S', 'M', 'M', 'L', 'XL', 'XL', '2XL', '3XL', '4XL'] },
            { name: 'T-Shirt', sizes: ['8', '10', '12', '12', '14', '16', '16', '18', '20', '20', '22'] },
            { name: 'Cardigan', sizes: ['6', '8', '10', '10', '12', '14', '16', '16', '18', '20', '22'] },
          ]
        },
        bottomMeasurements: {
          waistMinStops: [22.9, 25.4, 28.1, 30.5, 33.1, 35.8, 38.5, 41.2, 43.9, 46.6],
          waistRelaxStops: [25.3, 28.0, 30.4, 33.0, 35.7, 38.4, 41.1, 43.8, 46.5, 49.2],
          waistMeasurementTuples: [],
          sideLengths: [[31.0], [33.0], [35.0], [37.0], [39.0], [40.5], [42], [42], [42], [42]],
          mainBottom: {
            name: 'Shorts',
            sizes: [['18'], ['20'], ['22'], ['24'], ['26'], ['28'], ['30'], ['32'], ['34'], ['36']]
          },
          otherProducts: [
            { name: 'Belt', sizes: ['S', 'S', 'S', 'M', 'M', 'M', 'M', 'M', 'XL', 'XL'] },
          ]
        },
        accessories: [
          { grade: 'IV', name: 'Socks', sizes: ['4'] },
          { grade: 'V', name: 'Socks', sizes: ['5'] }
        ],
        images: {
          topMeasurementsImg: '../assets/images/dashboard/measurements/reinvent-uniform-top-measure.png',
          bottomMeasurementsImg: '../assets/images/dashboard/measurements/reinvent-uniform-bottom-measure.png'
        },
        legend: {
          topMeasurementsLegend: CHEST_MEASURE_LEGEND,
          bottomMeasurementsLegend: WAIST_MEASURE_LEGEND
        }
      },
      vi2x: {
        topMeasurements: {
          chestMinStops: [39.4, 41.3, 43.2, 45.1, 48.1, 51.3, 54.5, 57.7, 60.9, 60.9, 67.3],
          chestMaxStops: [41.2, 43.1, 45.0, 48.0, 51.2, 54.4, 57.6, 60.8, 64.0, 67.2, 70.4],
          chestMeasurementTuples: [],
          topLengths: [56.0, 59.5, 63.0, 67.0, 71.0, 73.5, 73.5, 73.5, 73.5, 73.5, 73.5],
          mainTop: { name: 'Tie Shirt', sizes: ['8', '10', '12', '14', '16', '18', '20', '22', '24', '26', '28'] },
          otherProducts: [
            { name: 'Waist Coat', sizes: ['XS', 'S', 'M', 'M', 'L', 'XL', 'XL', '2XL', '3XL', '4XL', '4XL'] },
            { name: 'T-Shirt', sizes: ['10', '12', '12', '14', '16', '16', '18', '20', '20', '22', '24'] },
            { name: 'Cardigan', sizes: ['8', '10', '10', '12', '14', '16', '16', '18', '20', '22', '22'] },
          ]
        },
        bottomMeasurements: {
          waistMinStops: [28.1, 30.5, 33.1, 35.8, 38.5, 41.2, 43.9, 46.6, 49.3, 51.8, 54.5, 57.2],
          waistRelaxStops: [30.4, 33.0, 35.7, 38.4, 41.1, 43.8, 46.5, 49.2, 51.7, 54.4, 57.1, 59.8],
          waistMeasurementTuples: [],
          sideLengths: [[87.0], [91.5], [96.0], [100.0], [105.0], [105.5], [106.0], [106.5], [107.5], [108.0], [108.5], [109.0]],
          mainBottom: {
            name: 'Trouser',
            sizes: [['22'], ['24'], ['26'], ['28'], ['30'], ['32'], ['34'], ['36'], ['38'], ['40'], ['42'], ['44']]
          },
          otherProducts: [
            { name: 'Belt', sizes: ['S', 'M', 'M', 'M', 'M', 'M', 'XL', 'XL', 'XL', 'XL', 'XL', 'XL'] },
          ]
        },
        accessories: [
          { grade: 'VI', name: 'Socks', sizes: ['5'] },
          { grade: 'VII', name: 'Socks', sizes: ['6'] },
          { grade: 'VIII', name: 'Socks', sizes: ['Free'] },
          { grade: 'IX', name: 'Socks', sizes: ['Free'] },
          { grade: 'X', name: 'Socks', sizes: ['Free'] }
        ],
        images: {
          topMeasurementsImg: '../assets/images/dashboard/measurements/reinvent-uniform-top-measure.png',
          bottomMeasurementsImg: '../assets/images/dashboard/measurements/Trouser.png'
        },
        legend: {
          topMeasurementsLegend: CHEST_MEASURE_LEGEND,
          bottomMeasurementsLegend: WAIST_MEASURE_LEGEND
        }
      }
    },
    girls: {
      nur2ii: {
        topMeasurements: {
          chestMinStops: [20.0, 23.5, 27.0, 30.5, 34.5, 38.5, 42.5],
          chestMaxStops: [23.4, 26.9, 30.4, 34.4, 38.4, 42.4, 46],
          chestMeasurementTuples: [[20.0, 23.4], [23.5, 26.9], [27.0, 30.4], [30.5, 34.4], [34.5, 38.4], [38.5, 42.4], [42.5, 46]],
          topLengths: [59.0, 64.0, 69.0, 74.0, 79.0, 83.0, 84.0],
          mainTop: { name: 'Frock', sizes: ['2XS', 'XS', 'S', 'M', 'L', 'XL', '2XL'] },
          otherProducts: [
            { name: 'Apron', sizes: ['S', 'M', 'M', 'L', 'L', 'XL', 'XL'] },
            { name: 'T-Shirt', sizes: ['P6', 'P8', 'P8', '10', '12', 'P14', 'P16'] },
            { name: 'Cardigan', sizes: ['4', '6', '6', '8', '10', '12', '14'] },
            { name: 'Cycling Shorts', sizes: ['XS', 'S', 'S', 'M', 'L', 'XL', '2XL'] }
          ]
        },
        bottomMeasurements: {
          waistMinStops: [20.4, 22.9, 25.4, 28.1, 30.5, 33.1, 35.8, 38.5, 41.2, 43.9],
          waistRelaxStops: [22.8, 25.3, 28.0, 30.4, 33.0, 35.7, 38.4, 41.1, 43.8, 46.5],
          waistMeasurementTuples: [],
          sideLengths: [[30.5], [32], [33], [34.5], [35.5], [37.0], [38.0], [38.0], [38.0], [38.0]],
          mainBottom: {
            name: 'String Skirt',
            sizes: [['16'], ['18'], ['20'], ['22'], ['24'], ['26'], ['28'], ['30'], ['32'], ['34']]
          },
          otherProducts: [
          ]
        },
        accessories: [
          { grade: 'Nursery', name: 'Socks', sizes: ['2'] },
          { grade: 'LKG', name: 'Socks', sizes: ['3'] },
          { grade: 'UKG', name: 'Socks', sizes: ['3'] },
          { grade: 'I', name: 'Socks', sizes: ['3'] },
          { grade: 'II', name: 'Socks', sizes: ['4'] }
        ],
        images: {
          topMeasurementsImg: '../assets/images/dashboard/measurements/Frock.jpg',
          bottomMeasurementsImg: '../assets/images/dashboard/measurements/String_Skirt.png'
        },
        legend: {
          topMeasurementsLegend: SHOULDER_MEASURE_LEGEND,
          bottomMeasurementsLegend: WAIST_MEASURE_LEGEND
        }
      },
      iii: {
        topMeasurements: {
          chestMinStops: [23.5, 27.0, 30.5, 34.5, 38.5, 42.5],
          chestMaxStops: [26.9, 30.4, 34.4, 38.4, 42.4, 46],
          chestMeasurementTuples: [[23.5, 26.9], [27.0, 30.4], [30.5, 34.4], [34.5, 38.4], [38.5, 42.4], [42.5, 46]],
          topLengths: [64.0, 69.0, 74.0, 79.0, 83.0, 84.0],
          mainTop: { name: 'Frock', sizes: ['XS', 'S', 'M', 'L', 'XL', '2XL'] },
          otherProducts: [
            { name: 'Apron', sizes: ['M', 'M', 'L', 'L', 'XL', 'XL'] },
            { name: 'T-Shirt', sizes: ['P8', 'P8', 'P10', 'P12', 'P14', 'P16'] },
            { name: 'Cardigan', sizes: ['6', '6', '8', '10', '12', '14'] },
          ]
        },
        bottomMeasurements: {
          waistMinStops: [22.1, 25.9, 29.7, 33.5, 37.5, 41.3, 46.2],
          waistRelaxStops: [25.8, 29.6, 33.4, 37.4, 41.2, 46.1, 50.8],
          waistMeasurementTuples: [],
          // waistMeasurementTuples: this.cha.girls.nur2ii.bottomMeasurements.waistMinStops.map((item, index) => {
          //   return [item, this.cha.girls.nur2ii.bottomMeasurements.waistRelaxStops[index]]
          // }),
          sideLengths: [[38], [43], [48.5], [53.5], [58.5], [61.0], [63.5]],
          mainBottom: {
            name: 'Pleated Skirt',
            sizes: [['3XS'], ['2XS'], ['XS'], ['S'], ['M'], ['L'], ['XL']]
          },
          otherProducts: [
            { name: 'Cycling Shorts', sizes: ['S', 'S', 'M', 'L', 'XL', '2XL', '3XL'] },
            { name: 'Belt', sizes: ['S', 'S', 'S', 'M', 'M', 'M', 'XL'] }

          ]
        },
        accessories: [
          { grade: 'III', name: 'Socks', sizes: ['4'] }
        ],
        images: {
          topMeasurementsImg: '../assets/images/dashboard/measurements/Frock.jpg',
          bottomMeasurementsImg: '../assets/images/dashboard/measurements/Pleated_Skirt.png'
        },
        legend: {
          topMeasurementsLegend: SHOULDER_MEASURE_LEGEND,
          bottomMeasurementsLegend: WAIST_MEASURE_LEGEND
        }
      },
      iv2x: {
        topMeasurements: {
          chestMinStops: [37.5, 39.4, 41.3, 43.2, 45.1, 47.6, 50.2, 52.7, 55.3, 57.8, 60.3],
          chestMaxStops: [39.3, 41.2, 43.1, 45.0, 47.5, 50.1, 52.6, 55.2, 57.7, 60.2, 62.8],
          chestMeasurementTuples: [],
          topLengths: [52.0, 54.5, 57.5, 60.5, 64.0, 67.0, 68.5, 68.5, 68.5, 68.5, 68.5],
          mainTop: { name: 'Frill Shirt', sizes: ['6', '8', '10', '12', '14', '16', '18', '20', '22', '24', '26'] },
          otherProducts: [
            { name: 'Waist Coat', sizes: ['XS', 'XS', 'S', 'M', 'M', 'L', 'XL', 'XL', '2XL', '3XL', '4XL'] },
            { name: 'T-Shirt', sizes: ['8', '10', '12', '12', '14', '16', '16', '18', '18', '20', '22'] },
            { name: 'Cardigan', sizes: ['6', '8', '10', '10', '12', '14', '16', '16', '18', '18', '20'] },
          ]
        },
        bottomMeasurements: {
          waistMinStops: [25.9, 29.7, 33.5, 37.5, 41.3, 46.2, 50.9],
          waistRelaxStops: [29.6, 33.4, 37.4, 41.2, 46.1, 50.8, 56.6],
          waistMeasurementTuples: [],
          // waistMeasurementTuples: this.cha.girls.nur2ii.bottomMeasurements.waistMinStops.map((item, index) => {
          //   return [item, this.cha.girls.nur2ii.bottomMeasurements.waistRelaxStops[index]]
          // }),
          sideLengths: [[43.0], [48.5], [53.5], [58.5], [61.0], [63.5], [66.0]],
          mainBottom: {
            name: 'Pleated Skirt',
            sizes: [['2XS'], ['XS'], ['S'], ['M'], ['L'], ['XL'], ['2XL']]
          },
          otherProducts: [
            { name: 'Cycling Shorts', sizes: ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL'] },
            { name: 'Belt', sizes: ['S', 'S', 'M', 'M', 'M', 'XL', 'XL'] }
          ]
        },
        accessories: [
          { grade: 'IV', name: 'Socks', sizes: ['4'] },
          { grade: 'V', name: 'Socks', sizes: ['5'] },
          { grade: 'VI', name: 'Socks', sizes: ['5'] },
          { grade: 'VII', name: 'Socks', sizes: ['6'] },
          { grade: 'VIII', name: 'Socks', sizes: ['Free'] },
          { grade: 'IX', name: 'Socks', sizes: ['Free'] },
          { grade: 'X', name: 'Socks', sizes: ['Free'] }
        ],
        images: {
          topMeasurementsImg: '../assets/images/dashboard/measurements/reinvent-uniform-top-measure.png',
          bottomMeasurementsImg: '../assets/images/dashboard/measurements/Pleated_Skirt.png'
        },
        legend: {
          topMeasurementsLegend: CHEST_MEASURE_LEGEND,
          bottomMeasurementsLegend: WAIST_MEASURE_LEGEND
        }
      }
    }
  };

  suggestedTopLengths = [59.0];
  suggestedBottomLengths = [30.5];
  waistMeasurement = 0;
  sideLengthBottom = 0;
  chestMeasurement = 0;
  topMeasurementImage = '';
  bottomMeasurementImage = '';
  topMeasurementLegend = '';
  bottomMeasurementLegend = '';
  sideLengthTop = 0;
  finalSizeMaps = [];
  schoolNGrades = {};
  viewModel = [];
  sizeObject: any = {};

  sizes: [
    { name: 'Product 1', size: 'Size A' },
    { name: 'Product 2', size: 'Size B' },
    { name: 'Product 3', size: 'Size C' },
  ];



  matchedWaistMeasurementIndex = 1;
  matchedChestMeasurementIndex = 1;

  // suggestedSizeArray = [];
  suggestedSizeKeys = [];
  suggestedSizeObj = {};

  ngOnInit() {
    if (this.selectedSchool === BASE_SCHOOL) {
      this.schoolNGrades = this.cha.girls.nur2ii;
      this.sizeMeasurementHelpModal({ temp: 'value' });
    }
    this.setupMeasurementRanges();
  }

  setupMeasurementRanges = () => {
    this.cha.boys.nur2ukg.topMeasurements.chestMeasurementTuples = this.cha.boys.nur2ukg.topMeasurements.chestMinStops.map((item, index) =>
      [item, this.cha.boys.nur2ukg.topMeasurements.chestMaxStops[index]]
    );
    this.cha.boys.i2iii.topMeasurements.chestMeasurementTuples = this.cha.boys.i2iii.topMeasurements.chestMinStops.map((item, index) =>
      [item, this.cha.boys.i2iii.topMeasurements.chestMaxStops[index]]
    );
    this.cha.boys.iv2v.topMeasurements.chestMeasurementTuples = this.cha.boys.iv2v.topMeasurements.chestMinStops.map((item, index) =>
      [item, this.cha.boys.iv2v.topMeasurements.chestMaxStops[index]]
    );
    this.cha.boys.vi2x.topMeasurements.chestMeasurementTuples = this.cha.boys.vi2x.topMeasurements.chestMinStops.map((item, index) =>
      [item, this.cha.boys.vi2x.topMeasurements.chestMaxStops[index]]
    );
    this.cha.boys.nur2ukg.bottomMeasurements.waistMeasurementTuples =
      this.cha.boys.nur2ukg.bottomMeasurements.waistMinStops.map((item, index) =>
        [item, this.cha.boys.nur2ukg.bottomMeasurements.waistRelaxStops[index]]
      );
    this.cha.boys.i2iii.bottomMeasurements.waistMeasurementTuples =
      this.cha.boys.i2iii.bottomMeasurements.waistMinStops.map((item, index) =>
        [item, this.cha.boys.i2iii.bottomMeasurements.waistRelaxStops[index]]
      );
    this.cha.boys.iv2v.bottomMeasurements.waistMeasurementTuples =
      this.cha.boys.iv2v.bottomMeasurements.waistMinStops.map((item, index) =>
        [item, this.cha.boys.iv2v.bottomMeasurements.waistRelaxStops[index]]
      );
    this.cha.boys.vi2x.bottomMeasurements.waistMeasurementTuples =
      this.cha.boys.vi2x.bottomMeasurements.waistMinStops.map((item, index) =>
        [item, this.cha.boys.vi2x.bottomMeasurements.waistRelaxStops[index]]
      );
    this.cha.girls.nur2ii.topMeasurements.chestMeasurementTuples = this.cha.girls.nur2ii.topMeasurements.chestMinStops.map((item, index) =>
      [item, this.cha.girls.nur2ii.topMeasurements.chestMaxStops[index]]
    );
    this.cha.girls.iii.topMeasurements.chestMeasurementTuples = this.cha.girls.iii.topMeasurements.chestMinStops.map((item, index) =>
      [item, this.cha.girls.iii.topMeasurements.chestMaxStops[index]]
    );
    this.cha.girls.iv2x.topMeasurements.chestMeasurementTuples = this.cha.girls.iv2x.topMeasurements.chestMinStops.map((item, index) =>
      [item, this.cha.girls.iv2x.topMeasurements.chestMaxStops[index]]
    );
    this.cha.girls.nur2ii.bottomMeasurements.waistMeasurementTuples =
      this.cha.girls.nur2ii.bottomMeasurements.waistMinStops.map((item, index) =>
        [item, this.cha.girls.nur2ii.bottomMeasurements.waistRelaxStops[index]]
      );
    this.cha.girls.iii.bottomMeasurements.waistMeasurementTuples =
      this.cha.girls.iii.bottomMeasurements.waistMinStops.map((item, index) =>
        [item, this.cha.girls.iii.bottomMeasurements.waistRelaxStops[index]]
      );
    this.cha.girls.iv2x.bottomMeasurements.waistMeasurementTuples =
      this.cha.girls.iv2x.bottomMeasurements.waistMinStops.map((item, index) =>
        [item, this.cha.girls.iv2x.bottomMeasurements.waistRelaxStops[index]]
      );
  }

  sizeSelectedGroup = (productId, variantId) => {
    // this.sizeObject[productId] = variantId;
    let temp;
    if (this.sizeObject[productId] !== undefined) {
      temp = this.sizeObject[productId];
      temp['vid'] = variantId;
      this.sizeObject[productId] = temp;
    } else {
      temp = { vid: variantId };
      this.sizeObject[productId] = temp;
    }

  }

  // Section - Size measurement functions

  saveMeasurements = () => {

    if (!this.chestMeasured) {
      this.alertMessage = 'You must specify "' + this.topMeasurementLegend + '".';
      this.showAlert = true;
      alert(this.alertMessage);
      return;
    } else if (!this.waistMeasured) {
      this.alertMessage = 'You must specify "' + this.bottomMeasurementLegend + '".';
      this.showAlert = true;
      alert(this.alertMessage);
      return;
    } else if (!this.sideLengthMeasurementTaken) {
      this.alertMessage = 'You must Select Length for "Main - Bottom" from the drop-down values';
      this.showAlert = true;
      return;
    } else if (!this.topMeasurementInRange) {
      this.alertMessage = this.chestMeasurement + ' is outside the specified range.';
      this.showAlert = true;
      alert(this.alertMessage);
      return;
    } else if (!this.bottomMeasurementInRange) {
      this.alertMessage = this.waistMeasurement + ' is outside the specified range.';
      this.showAlert = true;
      alert(this.alertMessage);
      return;
    }

    this.alertMessage = '';
    this.showAlert = false;

    interface IProductSizes { name: any; sizes: any; }
    // tslint:disable-next-line:prefer-const
    // tslint:disable-next-line:no-angle-bracket-type-assertion
    const bottomMeasurementsOtherProducts: any = this.schoolNGrades['bottomMeasurements']['otherProducts'].map(item => <IProductSizes>{
      name: item.name,
      sizes: item.sizes
    });

    // tslint:disable-next-line:no-string-literal
    // tslint:disable-next-line:no-angle-bracket-type-assertion
    // tslint:disable-next-line:no-string-literal
    // tslint:disable-next-line:no-angle-bracket-type-assertion
    // tslint:disable-next-line:no-string-literal
    const topMeasurementsOtherProducts: any = this.schoolNGrades['topMeasurements']['otherProducts']
      // tslint:disable-next-line:no-angle-bracket-type-assertion
      .map(element => <IProductSizes> {
        name: element.name,
        sizes: element.sizes
      });

      
    // tslint:disable-next-line:no-string-literal
    const mainBottomSizesArray = this.schoolNGrades['bottomMeasurements']['mainBottom']['sizes'][this.matchedChestMeasurementIndex];
    const mainBottomMeasurement = mainBottomSizesArray.length > 1 ? '28LXXX' : mainBottomSizesArray[0];
    this.finalSizeMaps = [
      // tslint:disable-next-line:no-string-literal
      {
        name: [this.schoolNGrades['topMeasurements']['mainTop']['name']],
        // tslint:disable-next-line:no-string-literal
        size: this.schoolNGrades['topMeasurements']['mainTop']['sizes'][this.matchedChestMeasurementIndex]
      },
      //  {name: [this.schoolNGrades['bottomMeasurements']['mainBottom']['name']], size: 'Size ' + mainBottomMeasurement }
      // tslint:disable-next-line:no-string-literal
      {
        name: [this.schoolNGrades['bottomMeasurements']['mainBottom']['name']],
        // tslint:disable-next-line:no-string-literal
        size: this.schoolNGrades['bottomMeasurements']['mainBottom']['sizes'][this.matchedWaistMeasurementIndex]
      }
      // {name: [this.schoolNGrades['accessories'][0]['name']],  size: 'Size ' + this.schoolNGrades['accessories'][0]['sizes'][0] }
    ];
    bottomMeasurementsOtherProducts.forEach((element, index) => {

      element.sizes.some((item, eIndex) => {
        if (eIndex === this.matchedWaistMeasurementIndex) {
          this.finalSizeMaps.push({ name: [element.name], size: element.sizes[eIndex] })
        }
      });
    });
    topMeasurementsOtherProducts.forEach((element, index) => {

      element.sizes.some((item, eIndex) => {
        if (eIndex === this.matchedChestMeasurementIndex) {
          this.finalSizeMaps.push({ name: [element.name], size: element.sizes[eIndex] })
        }
      });
    });
    // tslint:disable-next-line:no-string-literal
    this.schoolNGrades['accessories'].forEach((acc, indx) => {
      let thisAccessory = {};
      if (acc.grade === this.groupSelectedGradeValue) {
        thisAccessory = {
          name: acc.name,
          size: 'Size ' + acc.sizes[0]
        };
        this.finalSizeMaps.push(thisAccessory)
      }
    });

    this.savedMeasurements = true;
    this.editing = false;
    this.viewingResults = true;
    this.checkShowSizes();
  }

  resetMeasurements = () => {
    this.bottomLength.reset();
    this.genderSelector.reset();
    this.groupSelector.reset();
    this.chestm.reset();
    this.waistm.reset();
    this.schoolSelected = false;
    this.sideLengthMeasurementTaken = false;
    this.showSizes = false;
    this.savedMeasurements = false;
    this.viewingResults = false;
    this.editing = true;
    this.showMeasurementPanel = false;
  }
  saveChestMeasurement(e) {
    e = (e) ? e : window.event;
    // tslint:disable-next-line:prefer-const
    let charCode = (e.which) ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  saveWaistMeasurement(e) {
    e = (e) ? e : window.event;
    // tslint:disable-next-line:prefer-const
    let charCode = (e.which) ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  setSideLengthValue(productId, quantity) {
    // this.sizeObject[productId] = variantId;
    let temp;
    if (this.sizeObject[productId] !== undefined) {
      temp = this.sizeObject[productId];
      // tslint:disable-next-line:no-string-literal
      temp['qty'] = quantity;
      this.sizeObject[productId] = temp;
    } else {
      temp = { qty: quantity };
      this.sizeObject[productId] = temp;
    }
  }

  sizeMeasurementHelpModal(studentSuggestedSize) {
    // this.suggestedSizeKeys = Object.keys(studentSuggestedSize) || [];
  }

  sizeMeasurementAYSModal() {
    this.suggestedSizeKeys = [];
    // if(typeof studentAskSure === 'object') {
    //   this.suggestedSizeKeys = Object.keys(studentAskSure)
    // }
  }
  //Chest measurement and top length calculation

  /**
   * 
   * @param chestMeasurement
   */
  saveChestMeasurementData = (chestMeasurement, eventName) => {
    if ( chestMeasurement.length <= 0 ) {
      return;
    }
    if ( eventName && eventName === 'focusout' ) {
      if (chestMeasurement < this.schoolNGrades['topMeasurements']['chestMinStops'][0] ||
      chestMeasurement >
      this.schoolNGrades['topMeasurements']['chestMinStops'][ this.schoolNGrades['topMeasurements']['chestMinStops'].length - 1 ]) {
        this.alertMessage = chestMeasurement + ' is outside the specified range.';
        this.topMeasurementInRange = false;
        this.topMeasurementLegendColor = OUR_RED;
        alert( this.alertMessage );
      } else {
        this.topMeasurementInRange = true;
        this.topMeasurementLegendColor = OUR_BLUE;
      }
    }
    this.showAlert = false;
    this.alertMessage = '';
    this.chestMeasured = true;
    this.chestMeasurement = parseFloat(chestMeasurement);
    this.schoolNGrades['topMeasurements']['chestMeasurementTuples'].some(this.sideLengthExistsInMeasurements.bind(this));
    this.checkShowSizes();
  }
  /**
   *
   * @param element
   * @param index
   * @param array
   */
  sideLengthExistsInMeasurements(element, index, array) {
    let lengthNum = this.chestMeasurement || 0;
    if (lengthNum >= element[0] && lengthNum <= element[1]) {
      this.matchedChestMeasurementIndex = index;
      this.suggestedTopLengths = [this.schoolNGrades['topMeasurements']['topLengths'][parseInt(index)]]
      this.sideLengthTop = this.suggestedTopLengths[0];

      return true;
    }
    return false;
  }
  //Waist measurement and bottom length calculation & selection
  /**
   *
   * @param waistMeasurement;
   */
  saveWaistMeasurementData = (waistMeasurement, eventName) => {
    if ( waistMeasurement && waistMeasurement.length <= 0 ) {
      return;
    }
    if ( eventName && eventName === 'focusout' ){
      if (waistMeasurement < this.schoolNGrades['bottomMeasurements']['waistMinStops'][0] ||
      waistMeasurement >
          this.schoolNGrades['bottomMeasurements']['waistMinStops'][ this.schoolNGrades['bottomMeasurements']['waistMinStops'].length - 1 ]) {
        this.alertMessage = waistMeasurement + ' is outside the specified range.';
        this.bottomMeasurementInRange = false;
        this.bottomMeasurementLegendColor = OUR_RED;
        alert( this.alertMessage );
      } else {
        this.bottomMeasurementInRange = true;
        this.bottomMeasurementLegendColor = OUR_BLUE;
      }
            
    }

    this.showAlert = false;
    this.alertMessage = '';
    this.waistMeasured = true;

    this.waistMeasurement = parseFloat(waistMeasurement);
    this.bottomLengthSelected(this.suggestedBottomLengths[0]); // For Chaitanya, no selection of bottom length from array - Sundar 30JAN2020
    this.schoolNGrades['bottomMeasurements']['waistMeasurementTuples'].some(this.bottomLengthExistsInMeasurements.bind(this));
    this.checkShowSizes();
  }
  /**
   *
   * @param element
   * @param index
   * @param array
   */
  bottomLengthExistsInMeasurements(element, index, array) {
    let lengthNum = this.waistMeasurement || 0;
    if (lengthNum >= element[0] && lengthNum <= element[1]) {
      this.matchedWaistMeasurementIndex = index;
      this.suggestedBottomLengths = this.schoolNGrades['bottomMeasurements']['sideLengths'][parseInt(index)]

      return true;
    }
    return false;
  }
  bottomLengthSelected(waistLength) {
    // this.sizeObject[productId] = variantId;
    this.sideLengthMeasurementTaken = true;
    this.showAlert = false;
    this.alertMessage = '';
    this.sideLengthDisplay = waistLength;
    this.sideLengthBottom = parseFloat(waistLength);
  }

  schoolSelection = (schoolName) => {

    this.schoolSelected = true;
    this.checkShowMeasurementPanel();
  }

  genderSelection = (genderOption) => {
    this.genderSelected = true;
    this.showSizes = false;
    this.genderSelectedValue = genderOption;
    this.checkShowMeasurementPanel();
    if (genderOption === 'boys') {
      this.groupOptions = this.boysGroupOptions;
      this.groupsGradeMap = this.boysGroupsGradeMap;
    } else if (genderOption === 'girls') {
      this.groupOptions = this.girlGroupOptions;
      this.groupsGradeMap = this.girlsGroupsGradeMap;
    }
  }

  groupSelection = (groupOptionValue) => {
    if (groupOptionValue) {
      const groupOption = JSON.parse(groupOptionValue);

      this.groupSelected = true;
      this.showSizes = false;
      this.groupSelectedValue = groupOption.key;
      this.groupSelectedGradeValue = groupOption.value;
      this.checkShowMeasurementPanel();
      this.setSchoolGenderGroup();
    }
  }

  setSchoolGenderGroup = () => {
    if (this.genderSelected && this.groupSelected && this.genderSelectedValue.length > 0 && this.groupSelectedValue.length > 0) {
      if (this.selectedSchool === BASE_SCHOOL) {
        this.schoolNGrades = this.cha[this.genderSelectedValue][this.groupSelectedValue];
        this.topMeasurementImage = this.cha[this.genderSelectedValue][this.groupSelectedValue]['images']['topMeasurementsImg'];
        this.bottomMeasurementImage = this.cha[this.genderSelectedValue][this.groupSelectedValue]['images']['bottomMeasurementsImg'];
        this.topMeasurementLegend = this.cha[this.genderSelectedValue][this.groupSelectedValue]['legend']['topMeasurementsLegend'];
        this.bottomMeasurementLegend = this.cha[this.genderSelectedValue][this.groupSelectedValue]['legend']['bottomMeasurementsLegend'];
        this.sizeMeasurementHelpModal({ 'temp': 'value' });
      }
    }
  }
  checkShowMeasurementPanel = () => {
    this.showMeasurementPanel = this.schoolSelected && this.genderSelected && this.groupSelected;

  }

  checkShowSizes = () => {
    this.alertMessage = '';
    this.showSizes = this.chestMeasured && this.waistMeasured;

  }
  /**
   * End section - Size measurement functions
   */

}
